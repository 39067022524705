
import { Component, Vue, Provide, Inject } from "vue-property-decorator";

@Component
export default class App extends Vue {
    @Provide() password = "960807";
    @Provide() passwordInit() {
        const password = window.sessionStorage.getItem("password");
        if (password !== this.password) {
            this.$route.name !== "Password" && this.$router.push("/password");
        } else if (this.$route.name === "Password") {
            this.$router.push("/");
        }
    }
    onClickLeft() {
        this.$router.go(-1);
    }
}
