import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'List',
    component: () => import('@/views/List.vue'),
  },
  {
    path: '/detail/:id(\\d+)',
    name: 'Detail',
    meta: { title: '房间信息' },
    component: () => import('@/views/Detail.vue'),
  },
  {
    path: '/person/:id(\\d+)',
    name: 'Person',
    meta: { title: '成员管理' },
    component: () => import('@/views/Person.vue'),
  },
  {
    path: '/log/:id(\\d+)',
    name: 'Log',
    meta: { title: '记录信息' },
    component: () => import('@/views/Log.vue'),
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('@/views/Info.vue'),
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('@/views/Password.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
